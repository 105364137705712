import { useCallback, useEffect, useMemo } from 'react'
import { DocumentNode } from 'graphql'
import { Draft } from 'immer'
import { useRouter } from 'next/router'
import { JotaiForm } from '../../components/state/useJotaiForm'
import { useJotaiUrqlQuery, WithUrql } from '../../components/state/useJotaiUrqlQuery'
import {
  ApprovalSegmentsDocument,
  BuildDryRunOrderFromAnswersDocument,
  BuildDryRunOrderFromAnswersQuery,
  BuildDryRunOrderFromAnswersQueryVariables,
  CustomFieldParentType,
  Cycle,
  DuplicateOrderDocument,
  DuplicateOrderQuery,
  DuplicateOrderQueryVariables,
  Feature,
  GenerateAmendmentDocument,
  GenerateAmendmentQuery,
  GenerateAmendmentQueryVariables,
  GenerateCancelOrderDocument,
  GenerateCancelOrderQuery,
  GenerateCancelOrderQueryVariables,
  GenerateRenewalOrderDocument,
  GenerateRenewalOrderQuery,
  GenerateRenewalOrderQueryVariables,
  GetCurrentUserDocument,
  GetCustomFieldsDocument,
  GetCustomFieldsQuery,
  GetCustomFieldsQueryVariables,
  GetOrderDetailDocument,
  GetOrderDetailQuery,
  GetOrderDetailQueryVariables,
  GetOrderExpiryDurationDocument,
  GetOrderExpiryDurationQuery,
  GetOrderExpiryDurationQueryVariables,
  GetPaymentTermSettingsDocument,
  GetPaymentTermSettingsQuery,
  GetPaymentTermSettingsQueryVariables,
  InputCustomBillingRecurrence,
  OrderType,
} from '../../generated/graphql'
import { notEmpty } from '../../util/array'
import { getDocumentName } from '../../util/gqlUtil'
import buildLogger from '../../util/logger'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { useUpdateApprovalSegments } from './useUpdateApprovalSegments'
import { useUpdateDefaultOrderOwner } from './useUpdateDefaultOrderOwner'
import { deepMutable } from '../../components/SchemaForm/DeepMutable'
import { addDurationToUnixDate, currentDateTimeInTenantTz } from '../../util/datetime/luxon/dateUtil'
import { Duration } from 'luxon'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { populateCustomFieldsWithDefault, populateOrderCustomFieldDefault } from '@/components/CustomFields/utils'
import { useCustomizationProps, withCustomizationDefaultValues } from '@/components/state/context/customizationContext'
import { useAtom } from 'jotai'
import { quoteBuilderOrderAtomWithPersistence } from '@/laboratoryComponents/experiments/QuoteBuilder/QuoteBuilderProvider'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'
import { useGetCustomBillingSchedule } from '@/generated/rest'
import { useGetInvoiceTriggerSchedules } from './EditOrderPage/hooks/useInvoiceTriggerSchedules'

const logger = buildLogger('usePopulateOrder')

type UsePopulateOrderJotaiForm = Pick<CommonOrderFormPageState, 'orderDetail' | 'invoiceTriggerSchedules'> & WithUrql

export const useJotaiIsOrderRenewal = <F extends UsePopulateOrderJotaiForm>({
  jotaiForm,
}: {
  jotaiForm: JotaiForm<F>
}) => {
  const router = useRouter()
  const type = router.query.type as string | undefined
  const isRenewal = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Renewal || type === 'renewal', [type])
  )
  return isRenewal
}

const populateLineItemCustomFieldDefaults = (orderDetail) => {
  return deepMutable(
    orderDetail.lineItems?.map((lineItem) => {
      return {
        ...lineItem,
        customFields: populateCustomFieldsWithDefault(lineItem.customFields),
      }
    }) ?? []
  )
}

const useQueryNewAmendment = <F extends UsePopulateOrderJotaiForm>({
  jotaiForm,
  isNewAmendment,
}: {
  jotaiForm: JotaiForm<F>
  isNewAmendment: boolean
}) => {
  const router = useRouter()
  const subscriptionId = router.query.subscriptionId as string | undefined

  const customizationProps = useCustomizationProps({
    displayName: 'generateDetail',
    parentLocator: 'order::amendment::',
  })

  useJotaiUrqlQuery<F, GenerateAmendmentQuery, GenerateAmendmentQueryVariables>({
    document: GenerateAmendmentDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.orderDetail = deepMutable(
          withCustomizationDefaultValues(customizationProps?.defaultValueSetters, {
            ...data.generateAmendment,
            termLength: undefined,
            customFields: populateOrderCustomFieldDefault(data.generateAmendment),
            lineItems: populateLineItemCustomFieldDefaults(data.generateAmendment),
          })
        )
      },
      [customizationProps?.defaultValueSetters]
    ),
    pause: useCallback(() => !isNewAmendment, [isNewAmendment]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })
}

/**
 * Responsible for populating the orderDetails and related fields for new and existing orders.
 * Including renewals, duplicates, amendments, cancellations, and regular draft orders
 */
const usePopulateOrder = <F extends UsePopulateOrderJotaiForm>({ jotaiForm }: { jotaiForm: JotaiForm<F> }) => {
  const router = useRouter()
  const orderId = router.query.orderId as string
  const subscriptionId = router.query.subscriptionId as string | undefined
  const sourceId = router.query.sourceId as string | undefined
  const source = router.query.source as string | undefined
  const type = router.query.type as string | undefined

  const isNew = !orderId

  const isRenewal = useJotaiIsOrderRenewal({ jotaiForm })
  const isCancellation = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Cancel || type === 'cancel', [type])
  )
  const isAmendment = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.orderType === OrderType.Amendment || type === 'amendment', [type])
  )
  const isQuoteBuilderEnabled = useDynamicFeatureFlag(Feature.QuoteBuilder)
  const [quoteBuilderOrderPayload] = useAtom(quoteBuilderOrderAtomWithPersistence)

  const isNewRenewal = isRenewal && !!subscriptionId
  const isNewDuplicate = isNew && !!sourceId
  const isNewCancellation = isNew && isCancellation && !!subscriptionId
  const isNewAmendment = isNew && !!isAmendment && !!subscriptionId
  const isNewOrder = isNew && !isNewRenewal && !isNewDuplicate && !isNewCancellation && !isNewAmendment
  const isNewGuidedSellingOrder = isNew && isQuoteBuilderEnabled && source === 'quote-builder'

  useJotaiUrqlQuery<F, GetOrderDetailQuery, GetOrderDetailQueryVariables>({
    document: GetOrderDetailDocument,
    jotaiForm,
    onData: useCallback((data, draft: Draft<F>) => {
      const { sfdcOpportunityId, sfdcOpportunityName, sfdcOpportunityStage, sfdcOpportunityType, ...restOrderDetail } =
        data.orderDetail

      draft.orderDetail = deepMutable(restOrderDetail)
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(restOrderDetail)
      draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(restOrderDetail)

      // To prevent overwriting existing oppty with empty data
      if (sfdcOpportunityId) {
        draft.orderDetail.sfdcOpportunityId = sfdcOpportunityId
        draft.orderDetail.sfdcOpportunityName = sfdcOpportunityName
        draft.orderDetail.sfdcOpportunityStage = sfdcOpportunityStage
        draft.orderDetail.sfdcOpportunityType = sfdcOpportunityType
      }
    }, []),
    pause: useCallback(() => isNew, [isNew]),
    queryOpts: useMemo(() => ({ requestPolicy: 'cache-only' }), []),
    variables: useCallback(() => ({ id: orderId }), [orderId]),
  })

  useJotaiUrqlQuery<F, GetCustomFieldsQuery, GetCustomFieldsQueryVariables>({
    document: GetCustomFieldsDocument,
    jotaiForm,
    onData: useCallback((data, draft: Draft<F>) => {
      if (!draft.orderDetail.customFields) {
        draft.orderDetail.customFields = populateOrderCustomFieldDefault(data)
      }
    }, []),
    pause: useCallback(() => !isNew, [isNew]),
    variables: useCallback(() => ({ parentObjectType: CustomFieldParentType.Order }), []),
  })

  const { queueDryRun } = useDryRunActions()
  const { customOrderLineTotalAmount } = useGetInvoiceTriggerSchedules()

  useJotaiUrqlQuery<F, GenerateRenewalOrderQuery, GenerateRenewalOrderQueryVariables>({
    document: GenerateRenewalOrderDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        const {
          sfdcOpportunityId,
          sfdcOpportunityStage,
          sfdcOpportunityName,
          sfdcOpportunityType,
          ...restRenewalOrderData
        } = data.generateRenewalOrder

        draft.orderDetail = deepMutable(restRenewalOrderData)
        draft.orderDetail.customFields = populateOrderCustomFieldDefault(restRenewalOrderData)
        draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(restRenewalOrderData)

        // To prevent overwriting existing oppty with empty data
        if (sfdcOpportunityId) {
          draft.orderDetail.sfdcOpportunityId = sfdcOpportunityId
          draft.orderDetail.sfdcOpportunityName = sfdcOpportunityName
          draft.orderDetail.sfdcOpportunityStage = sfdcOpportunityStage
          draft.orderDetail.sfdcOpportunityType = sfdcOpportunityType
        }

        if (customOrderLineTotalAmount) {
          draft.invoiceTriggerSchedules = [
            {
              recurrenceWithCount: {
                recurrence: {
                  cycle: Cycle.PaidInFull,
                  step: 1,
                },
                count: 1,
              },
              amount: customOrderLineTotalAmount,
              triggerInstant: draft.orderDetail.startDate as number,
            },
          ]
        }

        queueDryRun()
      },
      [queueDryRun, customOrderLineTotalAmount]
    ),
    pause: useCallback(() => !isNewRenewal, [isNewRenewal]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<F, DuplicateOrderQuery, DuplicateOrderQueryVariables>({
    document: DuplicateOrderDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderDetail = deepMutable(data.duplicateOrder)
    }, []),
    pause: useCallback(() => !isNewDuplicate, [isNewDuplicate]),
    variables: useCallback(() => ({ orderId: sourceId || '' }), [sourceId]),
  })

  useJotaiUrqlQuery<F, BuildDryRunOrderFromAnswersQuery, BuildDryRunOrderFromAnswersQueryVariables>({
    document: BuildDryRunOrderFromAnswersDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderDetail = deepMutable(data.buildDryRunOrderFromAnswers)
    }, []),
    pause: useCallback(() => !isNewGuidedSellingOrder, [isNewGuidedSellingOrder]),
    variables: useCallback(
      () => quoteBuilderOrderPayload as BuildDryRunOrderFromAnswersQueryVariables,
      [quoteBuilderOrderPayload]
    ),
  })

  useQueryNewAmendment({ jotaiForm, isNewAmendment })
  useJotaiUrqlQuery<F, GenerateCancelOrderQuery, GenerateCancelOrderQueryVariables>({
    document: GenerateCancelOrderDocument,
    jotaiForm,
    onData: useCallback((data: GenerateCancelOrderQuery, draft) => {
      draft.orderDetail = deepMutable(data.generateCancelOrder)
      draft.orderDetail.customFields = populateOrderCustomFieldDefault(data.generateCancelOrder)
      draft.orderDetail.lineItems = populateLineItemCustomFieldDefaults(data.generateCancelOrder)
    }, []),
    pause: useCallback(() => !isNewCancellation, [isNewCancellation]),
    variables: useCallback(() => ({ subscriptionId: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<F, GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>({
    document: GetPaymentTermSettingsDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => (draft.orderDetail.paymentTerm = data.paymentTermSettings?.defaultPaymentTerm),
      []
    ),
    pause: useCallback(() => !isNewOrder, [isNewOrder]),
  })

  useJotaiUrqlQuery<F, GetOrderExpiryDurationQuery, GetOrderExpiryDurationQueryVariables>({
    document: GetOrderExpiryDurationDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      if (data.orderExpiryDurationInDays !== null && !draft.orderDetail.expiresOn) {
        draft.orderDetail.expiresOn = addDurationToUnixDate(
          currentDateTimeInTenantTz().toUnixInteger(),
          Duration.fromObject({ days: data.orderExpiryDurationInDays })
        )
      }
    }, []),
    pause: useCallback(() => !isNew, [isNew]),
  })

  const { data: customBillingSchedule, queryFn: getCustomBillingSchedule } = useGetCustomBillingSchedule()

  useEffect(() => {
    if (!orderId) {
      return
    }
    void getCustomBillingSchedule([orderId])
  }, [jotaiForm, orderId, getCustomBillingSchedule])

  useEffect(() => {
    if (!customBillingSchedule) {
      return
    }
    jotaiForm.set((draft) => {
      const invoiceTriggerSchedules = customBillingSchedule?.schedules?.map((schedule) => ({
        recurrenceWithCount: schedule.recurrenceWithCount as InputCustomBillingRecurrence,
        amount: schedule.amount ?? 0,
        triggerInstant: schedule.triggerInstant ?? 0,
      }))
      draft.invoiceTriggerSchedules = [...(draft.invoiceTriggerSchedules || []), ...(invoiceTriggerSchedules || [])]
    })
  }, [customBillingSchedule, jotaiForm])

  const populateOrderDocuments: DocumentNode[] = useMemo(() => {
    if (!isNew) {
      return [GetOrderDetailDocument]
    }
    if (isNewRenewal) {
      return [GenerateRenewalOrderDocument]
    }
    if (isNewDuplicate) {
      return [DuplicateOrderDocument]
    }
    if (isNewCancellation) {
      return [GenerateCancelOrderDocument]
    }
    if (isNewAmendment) {
      return [GenerateAmendmentDocument]
    }

    // new order
    return [GetPaymentTermSettingsDocument, GetOrderExpiryDurationDocument]
  }, [isNew, isNewRenewal, isNewDuplicate, isNewCancellation, isNewAmendment])
  const hasOrderLoaded = jotaiForm.useSelect(
    useCallback(
      (form) =>
        populateOrderDocuments
          .map((doc) => {
            const docName = getDocumentName(doc)
            return form.urqlSuccesses?.[docName]
          })
          .filter((flag) => !!flag).length === populateOrderDocuments.length,
      [populateOrderDocuments]
    )
  )

  useUpdateDefaultOrderOwner({
    jotaiForm,
    isNew,
    pause: !hasOrderLoaded,
  })

  useUpdateApprovalSegments({ jotaiForm })

  const allLoadingDocuments = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const hasOwner = !!form.orderDetail.owner
        return [
          ...populateOrderDocuments,
          hasOwner ? ApprovalSegmentsDocument : undefined,
          isNew ? GetCurrentUserDocument : undefined,
        ].filter(notEmpty)
      },
      [populateOrderDocuments, isNew]
    )
  )

  const hasAllLoaded = jotaiForm.useSelect(
    useCallback(
      (form) => {
        return (
          allLoadingDocuments
            .map((doc) => {
              const docName = getDocumentName(doc)
              return form.urqlSuccesses?.[docName]
            })
            .filter((flag) => !!flag).length === allLoadingDocuments.length
        )
      },
      [allLoadingDocuments]
    )
  )

  const populateOrderDetails = useMemo(
    () => ({
      hasLoaded: hasAllLoaded,
      isNew,
      orderId,
      isRenewal,
      isCancellation,
      isAmendment,
      isNewDuplicate,
    }),
    [hasAllLoaded, isNew, orderId, isRenewal, isCancellation, isAmendment, isNewDuplicate]
  )
  return populateOrderDetails
}

export default usePopulateOrder
