import { useBillyRouter } from '@/components/route/useBillyRouter'
import { BillyColumn } from '@/components/table/baseTable'
import { IconButton } from '@mui/material'
import { CloudDownloadOutlined } from '@mui/icons-material'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { download } from '@/util/file'
import TableInCardWithSearch from '@/components/table/tableInCardWithSearch'

function SignedOrderTable(): JSX.Element {
  const snackbarHandler = useSnackbarHandler()
  const router = useBillyRouter()
  const orderId = router.query.orderId as string

  const downloadDocument = (documentId: string) => {
    snackbarHandler.inProgressAlert('Downloading PDF...')
    const url = `/orders/${orderId}/pdf/${documentId}`
    download(url)
    setTimeout(() => {
      snackbarHandler.dismissAllAlert()
    }, 2000)
  }

  const columns: ReadonlyArray<BillyColumn> = [
    {
      Header: 'Id',
      accessor: 'id',
      dataType: 'string',
      size: 'shrink',
    },
    {
      Header: 'Document Id',
      accessor: 'document_id',
      dataType: 'string',
      size: 'shrink',
    },
    {
      Header: 'Signed On',
      accessor: 'created_on',
      dataType: 'date',
      sort: true,
    },
    {
      Header: 'Actions',
      dataType: 'none',
      Cell: (props) => {
        return (
          <IconButton
            onClick={() => {
              downloadDocument(props.row.id)
            }}
          >
            <CloudDownloadOutlined />
          </IconButton>
        )
      },
    },
  ]

  return (
    <TableInCardWithSearch
      columns={columns}
      table_name="generated_document"
      urlPath=""
      idField="id"
      title="Signed Documents"
      renderEmptyMessage="No signed documents exist."
      select={[
        { key: 'document_id', value: orderId },
        { key: 'is_signed_document', value: true },
      ]}
    />
  )
}

export default SignedOrderTable
