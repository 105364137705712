import { BillyMuiNumberField } from '@/components/input/JotaiMuiNumberField'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { ActionType } from '@/generated/graphql'
import { LineItemsTableOrderType } from '@/pageComponents/orders/LineItemsEditTable/LineItemsEditTable'
import { AddCircleOutlineOutlined, CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'
import { Box, Button, Grid, IconButton } from '@mui/material'
import { useCallback } from 'react'
import { LineItemPriceOverrideDialogState } from './LineItemPriceOverrideDialog'
import { usePriceTierOverrideReducer } from './usePriceTierOverrideReducer'
export interface TieredPriceOverrideContentProps<F extends LineItemPriceOverrideDialogState> {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
  lineItemIndex: number
}

export const TieredPriceOverrideFormId = 'TieredPriceOverrideForm'
export const TieredPriceOverrideContent = <F extends LineItemPriceOverrideDialogState>({
  jotaiForm,
  orderType,
  lineItemIndex,
}: TieredPriceOverrideContentProps<F>): JSX.Element => {
  const action = jotaiForm.useSelect(
    useCallback((form) => form.orderDetail.lineItems[lineItemIndex]?.action || ActionType.Add, [lineItemIndex])
  )

  const original = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const priceTiers = form.orderDetail.lineItems[lineItemIndex]?.charge.priceTiers || []
        const minQuantity = form.orderDetail.lineItems[lineItemIndex]?.charge.minQuantity
        const maxQuantity = form.orderDetail.lineItems[lineItemIndex]?.charge.maxQuantity
        return {
          priceTiers,
          minQuantity,
          maxQuantity,
        }
      },
      [lineItemIndex]
    )
  )

  const { tiers, errors, hasError, override, addTier, deleteTier, updateTier, revertTiers } =
    usePriceTierOverrideReducer({
      priceTiers: original.priceTiers,
      minQuantity: original.minQuantity,
      maxQuantity: original.maxQuantity,
    })

  const handleSave = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!hasError) {
        jotaiForm.set((draft) => {
          draft.orderDetail.lineItems[lineItemIndex].pricingOverride = override
        })
      }
    },
    [hasError, jotaiForm, lineItemIndex, override]
  )

  if (
    !(orderType === 'NEW' || orderType === 'RESTRUCTURE' || (orderType === 'AMENDMENT' && action === ActionType.Add))
  ) {
    return <></>
  }

  return (
    <form onSubmit={handleSave} onReset={revertTiers} id={TieredPriceOverrideFormId}>
      {tiers.map((tier, index) => (
        <Grid container spacing={2} key={index} sx={{ mt: 1 }}>
          <Grid item xs={4}>
            <BillyMuiNumberField
              error={errors[index].from}
              textFieldProps={{
                value: parseInt(tier.from),
                onChange: (value) => updateTier(index, { from: value, to: tier.to }),
                label: index === 0 ? 'From (optional)' : 'From',
                size: 'small',
                placeholder: '0',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <BillyMuiNumberField
              error={errors[index].to}
              textFieldProps={{
                value: parseInt(tier.to),
                onChange: (value) => updateTier(index, { from: tier.from, to: value }),
                label: index === tiers.length - 1 ? 'To (optional)' : 'To',
                size: 'small',
                placeholder: tier.untilQuantity === 'INF' ? 'No maximum' : '',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <BillyMuiNumberField
              error={errors[index].amount}
              textFieldProps={{
                value: tier.amount,
                onChange: (value) => updateTier(index, { amount: value || 0, from: tier.from, to: tier.to }),
                label: 'Price',
                size: 'small',
                placeholder: '0',
              }}
            />
          </Grid>
          <Grid item xs={1}>
            {index > 0 && (
              <IconButton onClick={() => deleteTier(index)} size="small">
                <CancelOutlinedIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
        <Button onClick={addTier} color="primary" startIcon={<AddCircleOutlineOutlined />}>
          Add Tier
        </Button>
      </Box>
    </form>
  )
}
