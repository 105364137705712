import {
  DunningReminderType,
  DunningSettingDetailsFragment,
  GetDunningSettingQuery,
  UpdateDunningSettingMutation,
} from '../generated/graphql'

const DUNNING_TYPE_MAPPING: Record<DunningReminderType, { description: string; label: string; index: number }> = {
  [DunningReminderType.MonthAfterDueDate]: {
    description: 'Send an Overdue Reminder Invoice one month after the due date.',
    label: 'Dunning - Past Due 30 Days',
    index: 30,
  },
  [DunningReminderType.WeekAfterDueDate]: {
    description: 'Send an Overdue Reminder an invoice is overdue 7 days after the due date.',
    label: 'Dunning - Past Due 7 Days',
    index: 7,
  },
  [DunningReminderType.WeekBeforeDueDate]: {
    description: 'Send an Early Reminder an invoice is due in 7 days. ',
    label: 'Dunning - Upcoming Due',
    index: -7,
  },
  [DunningReminderType.TwoWeeksAfterDueDate]: {
    description: 'Send an Overdue Reminder Invoice 14 days after the due date.',
    label: 'Dunning - Past Due 14 Days',
    index: 14,
  },
  [DunningReminderType.DueDate]: {
    description: 'Send a Current Notice that an invoice is now due.',
    label: 'Current Notice - Due Now',
    index: 0,
  },
  [DunningReminderType.TwoMonthsAfterDueDate]: {
    description: 'Send an Overdue Reminder two months after the due date.',
    label: 'Dunning - Past Due 60 Days',
    index: 60,
  },
  [DunningReminderType.AutoPaymentFailed]: {
    description: 'Send a notice when an automatic payment fails.',
    label: 'Automatic Payment Failed',
    index: 900,
  },
  // Backend never sends this option, so it won't be visible
  [DunningReminderType.Manual]: {
    description: 'Manual reminder emails',
    label: 'Manual',
    index: 999,
  },
}
export function dunningTypeToDescription(dunningType: DunningReminderType): string {
  return DUNNING_TYPE_MAPPING[dunningType].description ?? ''
}

export function dunningTypeToLabel(dunningType: DunningReminderType): string {
  return DUNNING_TYPE_MAPPING[dunningType].label ?? ''
}

export function sortDunningType(d1: DunningReminderType, d2: DunningReminderType): number {
  const d1Object = DUNNING_TYPE_MAPPING[d1]
  const d2Object = DUNNING_TYPE_MAPPING[d2]
  if (d1Object || d2Object) {
    return d1Object.index - d2Object.index
  }
  return 0
}

export function mapDunningSettings(
  dunningSettings: GetDunningSettingQuery['dunningSetting'] | UpdateDunningSettingMutation['updateDunningSetting']
): DunningSettingDetailsFragment {
  return {
    ...dunningSettings,
    dunningTypeStatus: (
      [...(dunningSettings.dunningTypeStatus ?? [])] as {
        dunningType: DunningReminderType
        isEnabled: boolean
      }[]
    )
      .filter((d) => !!DUNNING_TYPE_MAPPING[d.dunningType])
      .sort((s1, s2) => sortDunningType(s1.dunningType, s2.dunningType)),
  }
}
