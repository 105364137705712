import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import {
  useDryRunActions,
  useShouldRecalculateTotals,
} from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '@/pageComponents/orders/EditOrderPage/NewOrderPage'
import { JotaiBlockQuantitySelectCell } from '@/pageComponents/orders/LineItemsEditTable/Cells/JotaiBlockQuantitySelectCell'
import { reduceLineItem } from '@/pageComponents/orders/LineItemsEditTable/reduceLineItem'
import buildLogger from '@/util/logger'
import { TextFieldProps } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionType, ChargeModel, ChargeType, Cycle } from '../../../../generated/graphql'
import { formatQuantity } from '../../../../util/priceTier'
import { LineItemsTableOrderType } from '../LineItemsEditTable'
import { OrderItemRowProps, chargeHasNoQuantity, chargeQuantityEditable } from '../orderChargeRow'
import { JotaiNumberQuantityEditCell } from './JotaiNumberQuantitySelectCell'
import { QuantityViewCell } from './QuantityViewCell'
const logger = buildLogger('ChargeRowQuantityCell')

export type ChargeRowQuantitySelectMode = 'edit' | 'view'

export type ChargeRowQuantityCellProps = {
  lineItemIndex: number
  currency?: string | null
  orderType: LineItemsTableOrderType
} & Pick<OrderItemRowProps, 'orderType'>

export function ChargeRowQuantityCell({ orderType, currency, lineItemIndex }: ChargeRowQuantityCellProps) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const {
    lineItem,
    resetToQuantity,
    charge,
    mode,
    displayQuantity,
    isChargeQuantityEditable,
    canEditBeToggled,
    onClickPencil,
    editFieldTextProps,
    editActionProps,
    showResetQuantity,
    isOrderAmendmentAndBillingCycleCustom,
  } = useChargeRowQuantityProps({
    lineItemIndex,
    orderType,
    currency,
  })

  return (
    <>
      {['edit'].includes(mode) &&
        (lineItem.charge.chargeModel === ChargeModel.Block ? (
          <JotaiBlockQuantitySelectCell
            lineItemIndex={lineItemIndex}
            jotaiForm={jotaiForm}
            lineItem={lineItem}
            charge={charge}
            textFieldProps={editFieldTextProps}
          />
        ) : (
          <JotaiNumberQuantityEditCell
            lineItemIndex={lineItemIndex}
            jotaiForm={jotaiForm}
            textFieldProps={editFieldTextProps}
          />
        ))}
      {['view'].includes(mode) && (
        <QuantityViewCell
          showPencil={isChargeQuantityEditable && canEditBeToggled && !isOrderAmendmentAndBillingCycleCustom}
          showResetQuantity={showResetQuantity}
          resetToQuantity={resetToQuantity}
          displayQuantity={displayQuantity}
          disabledExplanation={editActionProps.disabledExplanation}
          onClickPencil={onClickPencil}
        />
      )}
    </>
  )
}

function useChargeRowQuantityProps({ lineItemIndex, orderType, currency }: ChargeRowQuantityCellProps) {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { lineItem, amendmentLineInAmendmentPeriod, resetToQuantity, charge, isOrderAmendmentAndBillingCycleCustom } =
    jotaiForm.useSelect(
      useCallback(
        (form: NewOrderFormData) => {
          const reduced = reduceLineItem({
            orderDetail: form.orderDetail,
            lineItemIndex,
            orderType,
            currency,
          })
          const chargeRowQuantityProps = {
            lineItem: {
              action: reduced.lineItem.action,
              charge: reduced.lineItem.charge,
              quantity: reduced.lineItem.quantity,
              subscriptionChargeId: reduced.lineItem.subscriptionChargeId,
            },
            charge: reduced.charge,
            resetToQuantity: reduced.resetToQuantity,
            amendmentLineInAmendmentPeriod: reduced.amendmentLineInAmendmentPeriod,
            isOrderAmendmentAndBillingCycleCustom:
              reduced.billingCycle === Cycle.Custom &&
              orderType === 'AMENDMENT' &&
              reduced.lineItem.action !== ActionType.Add,
          }
          return chargeRowQuantityProps
        },
        [lineItemIndex, orderType, currency]
      )
    )

  const [isEditingQuantity, setIsEditingQuantity] = useState(false)

  const noQuantity = chargeHasNoQuantity(charge)
  const cancellationQuantity = jotaiForm.useSelect(
    useCallback(
      (form) =>
        formatQuantity(
          form.orderDetail.currentSubscription?.charges.find((charge) => charge?.id === lineItem.subscriptionChargeId)
            ?.quantity ?? 0
        ),
      [lineItem.subscriptionChargeId]
    )
  )
  // Displayed Quantity
  // For Usage -> "N/A"
  // Cancellation Order -> show cancellation quantity
  // Others -> show line item quantity
  let displayQuantity = noQuantity ? 'N/A' : formatQuantity(lineItem.quantity ?? 0)
  displayQuantity = orderType === 'CANCELLATION' ? cancellationQuantity : displayQuantity

  const hasError = jotaiForm.useSelect(
    useCallback(
      (form) => {
        return !!Object.keys(form.yupErrors ?? {}).find((key) =>
          key.startsWith(`orderDetail.lineItems[${lineItemIndex}]`)
        )
      },
      [lineItemIndex]
    )
  )

  const isDryRunning = useShouldRecalculateTotals()

  const isChargeQuantityEditable = chargeQuantityEditable(charge)

  const showEditInput = orderType === 'NEW' || isEditingQuantity || hasError
  const mode = isChargeQuantityEditable && showEditInput && !isOrderAmendmentAndBillingCycleCustom ? 'edit' : 'view'

  useEffect(() => {
    if (!isDryRunning) {
      setIsEditingQuantity(false)
    }
  }, [isDryRunning])

  const canEditBeToggled =
    orderType === 'AMENDMENT' || (orderType === 'RESTRUCTURE' && lineItem.action !== ActionType.Remove)

  const { queueDryRun, clearDryRun } = useDryRunActions()

  const editableTextInputProps: TextFieldProps = useMemo(
    () => ({
      autoFocus: canEditBeToggled,
      onBlur: () => {
        queueDryRun()
        if (canEditBeToggled) {
          setIsEditingQuantity(false)
        }
      },
      onFocus: clearDryRun,
      placeholder: 'Quantity',
    }),
    [clearDryRun, queueDryRun, canEditBeToggled]
  )

  const editFieldTextProps: TextFieldProps | undefined = useMemo(
    () => (isDryRunning ? { disabled: true, placeholder: 'Quantity' } : editableTextInputProps),
    [editableTextInputProps, isDryRunning]
  )

  const handleEditClick = useCallback(() => {
    jotaiForm.set((form) => {
      const matchingLineItem = form.orderDetail.lineItems.find(
        (li) => li.subscriptionChargeId === lineItem.subscriptionChargeId
      )
      if (matchingLineItem) {
        if (matchingLineItem.action === ActionType.None) {
          matchingLineItem.action = ActionType.Update
        }
      }
    })
  }, [jotaiForm, lineItem.subscriptionChargeId])

  const editActionProps = useMemo(
    () => ({
      disabledExplanation: !isChargeQuantityEditable
        ? 'Not applicable for fixed or flat fee charges'
        : !canEditBeToggled
        ? 'Can only edit in an amendment order'
        : [ChargeType.OneTime, ChargeType.Prepaid].includes(lineItem.charge.type) &&
          ![ActionType.Add, ActionType.Restructure].includes(lineItem.action)
        ? 'Cannot modify an existing one time or prepaid charge'
        : amendmentLineInAmendmentPeriod
        ? 'Cannot edit a line item that ends before amendment start date'
        : '',
      onClick: handleEditClick,
    }),
    [
      isChargeQuantityEditable,
      canEditBeToggled,
      lineItem.charge.type,
      lineItem.action,
      amendmentLineInAmendmentPeriod,
      handleEditClick,
    ]
  )

  const showResetQuantity = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const updatedQuantity = form.orderDetail.lineItems[lineItemIndex]?.quantity ?? 0
        return canEditBeToggled && (!!resetToQuantity || resetToQuantity === 0) && resetToQuantity !== updatedQuantity
      },
      [canEditBeToggled, lineItemIndex, resetToQuantity]
    )
  )

  const onClickPencil = useCallback(() => {
    if (!editActionProps.disabledExplanation) {
      editActionProps.onClick?.()
      setIsEditingQuantity(true)
    }
  }, [editActionProps])

  return useMemo(
    () => ({
      lineItem,
      resetToQuantity,
      charge,
      mode,
      displayQuantity,
      isChargeQuantityEditable,
      canEditBeToggled,
      editFieldTextProps,
      editActionProps,
      showResetQuantity,
      onClickPencil,
      isOrderAmendmentAndBillingCycleCustom,
    }),
    [
      lineItem,
      resetToQuantity,
      charge,
      mode,
      displayQuantity,
      isChargeQuantityEditable,
      canEditBeToggled,
      editFieldTextProps,
      editActionProps,
      showResetQuantity,
      onClickPencil,
      isOrderAmendmentAndBillingCycleCustom,
    ]
  )
}
