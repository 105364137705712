import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'

type EsignInProgressDialogProps = {
  isOrderDocumentSigned?: boolean
}

type DialogProps = WithModalParams & EsignInProgressDialogProps
const formID = 'RevertSignedDocumentDialog'
function RevertSignedDocumentDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Revert to Draft</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Signature process for this order form is complete. Are you sure you want to move it back to draft ?
        </Typography>
        <Typography sx={{ mt: 2 }} variant="body2">
          Note: Signed order form will be available in the “Signed Order Forms” section on this order.
        </Typography>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Close'}
          buttonData={{
            label: 'Close',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Revert'}
          buttonData={{
            label: 'Revert',
            onClick: () => {
              onSubmit?.()
              onClose?.()
            },
            buttonProps: {
              type: 'submit',
              form: formID,
            },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useRevertSignedDocumentDialog(modalProps: DialogProps = {}) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: RevertSignedDocumentDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  return useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])
}
