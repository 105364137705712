import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Button, Grid } from '@mui/material'
import JotaiMuiDateField from '@/components/input/JotaiMuiDateField'
import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useCallback } from 'react'
import JotaiMuiNumberField from '@/components/input/JotaiMuiNumberField'
import { Cycle, InputCustomBillingPeriodInput } from '@/generated/graphql'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import { useGetInvoiceTriggerSchedules } from '../hooks/useInvoiceTriggerSchedules'
import { useOrderPageFormStyles } from '../hooks/useOrderPageFormStyles'
import { useTenantTimeZone } from '@/components/UserTenantSessionProvider/useTenantTimeZone'
import {
  subtractOneDayFromUnixDate,
  termLengthToDuration,
  unixTimeSecondsToDateStr,
  unixTimeSecondsToLuxonWithTz,
  unixToLuxonWithTz,
} from '@/util/datetime/luxon/dateUtil'
import { useDryRunActions } from '../context/DryRunActionsContext'
import { CancelAndRestructureFormData } from '../../CancelAndRestructureOrderForm'

export const InvoiceTriggerSchedules = () => {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState & CancelAndRestructureFormData>()

  const { invoiceSchedules, billingCycle, startDate, endDate, termLength } = jotaiForm.useSelect(
    useCallback(
      (form: CommonOrderFormPageState) => ({
        invoiceSchedules: form.invoiceTriggerSchedules || [],
        billingCycle: form.orderDetail.billingCycle,
        startDate: form.orderDetail.startDate,
        endDate: form.orderDetail.endDate,
        orderType: form.orderDetail.orderType,
        termLength: form.orderDetail.termLength,
      }),
      []
    )
  )

  const { classes } = useOrderPageFormStyles()

  const { customOrderLineTotalAmount, remainingAmount } = useGetInvoiceTriggerSchedules()

  const { queueDryRun } = useDryRunActions()

  const tenantTZ = useTenantTimeZone()

  const orderEndDate = endDate
    ? //End dates from BE are non-inclusive, so it always needs to be handled with -1 day
      unixTimeSecondsToDateStr(subtractOneDayFromUnixDate(endDate), tenantTZ)
    : unixToLuxonWithTz(subtractOneDayFromUnixDate(startDate), tenantTZ)
        .plus(termLengthToDuration(termLength))
        .toISODate()

  const handleAddInvoice = () => {
    let invoiceSchedules: InputCustomBillingPeriodInput[] = []
    jotaiForm.set((draft) => {
      invoiceSchedules = [
        ...(draft.invoiceTriggerSchedules || []),
        {
          amount: remainingAmount,
          triggerInstant: draft.orderDetail.startDate as number,
          recurrenceWithCount: { recurrence: { cycle: Cycle.PaidInFull, step: 1 }, count: 1 },
        },
      ]
      draft.invoiceTriggerSchedules = invoiceSchedules
    })
    void queueDryRun()
  }

  const handleRemoveInvoice = (index: number) => {
    let invoiceSchedules: InputCustomBillingPeriodInput[] = []
    jotaiForm.set((draft) => {
      invoiceSchedules = draft.invoiceTriggerSchedules?.filter((_, i) => i !== index) ?? []
      draft.invoiceTriggerSchedules = invoiceSchedules
    })
    void queueDryRun()
  }

  const handleDefineInvoiceCadence = () => {
    jotaiForm.set((draft) => {
      draft.invoiceTriggerSchedules = [
        {
          amount: customOrderLineTotalAmount,
          triggerInstant: draft.orderDetail.startDate as number,
          recurrenceWithCount: {
            recurrence: {
              cycle: Cycle.PaidInFull,
              step: 1,
            },
            count: 1,
          },
        },
      ]
    })
    void queueDryRun()
  }

  if (invoiceSchedules.length === 0 && billingCycle.cycle === Cycle.Custom) {
    return (
      <Grid item xs={12}>
        <Button
          variant="text"
          onClick={() => {
            void handleDefineInvoiceCadence()
          }}
        >
          Define Invoice Cadence
        </Button>
      </Grid>
    )
  }

  if (invoiceSchedules.length === 0 || billingCycle.cycle !== Cycle.Custom) {
    return <></>
  }

  return (
    <>
      {invoiceSchedules.map((schedule, index) => (
        <Grid key={index} container direction="row" spacing={2} className={classes.formRow}>
          <Grid item xs={6}>
            <JotaiMuiDateField
              atomSelector={(form) => form.invoiceTriggerSchedules?.[index]?.triggerInstant}
              atomUpdater={(value, draft) => {
                if (draft.invoiceTriggerSchedules) {
                  draft.invoiceTriggerSchedules[index].triggerInstant = value ?? undefined
                }
              }}
              datePickerProps={{
                label: 'Invoice Trigger Date',
                minDate: unixTimeSecondsToLuxonWithTz(startDate, tenantTZ).toISO(),
                maxDate: orderEndDate,
              }}
              errorPath={`invoiceTriggerSchedules[${index}].triggerInstant`}
              form={jotaiForm}
              disabledExplanation={
                schedule.amount != null && schedule.amount < 0
                  ? 'This invoice schedule is only for viewing purposes'
                  : undefined
              }
              onBlur={queueDryRun}
            />
          </Grid>
          <Grid item xs={4}>
            <JotaiMuiNumberField
              atomSelector={(form) => form.invoiceTriggerSchedules?.[index]?.amount}
              atomUpdater={(value, draft) => {
                if (draft.invoiceTriggerSchedules) {
                  draft.invoiceTriggerSchedules[index].amount = value
                } else {
                  draft.invoiceTriggerSchedules = [
                    {
                      amount: value,
                      triggerInstant: draft.orderDetail.startDate as number,
                      recurrenceWithCount: {
                        recurrence: { cycle: Cycle.PaidInFull, step: 1 },
                        count: 1,
                      },
                    },
                  ]
                }
              }}
              errorPath={`invoiceTriggerSchedules[${index}].amount`}
              form={jotaiForm}
              textFieldProps={{
                label: 'Amount',
                size: 'medium',
              }}
              disabledExplanation={
                schedule.amount != null && schedule.amount < 0
                  ? 'This invoice schedule is only for viewing purposes'
                  : undefined
              }
              onBlur={queueDryRun}
            />
          </Grid>
          <Grid item xs={2} display="flex">
            <Button
              variant="text"
              onClick={() => {
                void handleRemoveInvoice(index)
              }}
            >
              <CancelOutlined
                color="primary"
                style={{
                  visibility: schedule.amount != null && schedule.amount < 0 ? 'hidden' : undefined,
                }}
              />
            </Button>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={4}>
        <Button
          variant="text"
          onClick={() => {
            void handleAddInvoice()
          }}
        >
          Add Invoice
        </Button>
      </Grid>
    </>
  )
}
