import ActionMenu, { CustomMenuItemProps } from '@/components/menu/actionMenu'
import { ArrowDropDown } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  ButtonProps,
  CircularProgress,
  Stepper as MuiStepper,
  Orientation,
  Step,
  StepLabel,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { ReactElement, useEffect } from 'react'
import buildLogger from '../../util/logger'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import ActionButton from '../button/actionButton'
import { ActionMenuButton } from '../menu/actionMenuButton'
const logger = buildLogger('StepperFormProvider')
export type StepperFormCallbackHandler = (event?: React.MouseEvent) => void
type AsyncStepperFormCallbackHandler = (event?: React.MouseEvent) => Promise<void>

export type Step = {
  id: string
  label: string
  onNext?: StepperFormCallbackHandler | AsyncStepperFormCallbackHandler
  onBack?: StepperFormCallbackHandler | AsyncStepperFormCallbackHandler
  optional?: boolean
  // can be component as well, this needs to be type checked when rendered
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode | ((props: WithStepperFormProps & { stepIndex: number }) => React.ReactNode) | any
}
export type StepperFormRootProps = {
  steps: Step[]
  /**
   * stepper form children that which referenced by id
   */
  mode?: 'static' | 'dynamic'
  children?: React.ReactNode | ((props: StepperFormChildren) => React.ReactNode)
  initialActiveStepIndex?: number
  addStepValidation?: boolean
}

export type StepperFormChildren = {
  stepperFormProps?: WithStepperFormProps
}

export type WithStepperFormProps = {
  activeId: string
  isNextLoading: boolean
  isBackLoading: boolean
  activeStepIndex: number
  steps: Step[]
  isStepOptional: (index: number) => boolean
  isStepSkipped: (index: number) => boolean
  handleBack: StepperFormCallbackHandler
  handleNext: StepperFormCallbackHandler
  handleReset: StepperFormCallbackHandler
  handleSkip: StepperFormCallbackHandler
  initialActiveStepIndex?: number
  addStepValidation?: boolean
  isStepValid?: boolean
  setIsStepValid?: (status: boolean) => void
}

function useStepperFormProvider(
  steps: Step[],
  mode: 'static' | 'dynamic' = 'static',
  initialActiveStepIndex?: number,
  addStepValidation = false
) {
  const [activeStepIndex, setActiveStepIndex] = React.useState(initialActiveStepIndex ?? 0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const errorHandler = useErrorHandler()
  const [isNextLoading, setIsNextLoading] = React.useState(false)
  const [isBackLoading, setIsBackLoading] = React.useState(false)
  const [isStepValid, setIsStepValid] = React.useState(false)

  useEffect(() => {
    setActiveStepIndex(initialActiveStepIndex ?? activeStepIndex)
    setIsStepValid(false)
    // activeStepIndex is intentionally left out of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActiveStepIndex])

  const isStepOptional = (index: number) => {
    return !!steps[index]?.optional
  }

  const isStepSkipped = (index: number) => {
    return skipped.has(index)
  }

  const handleNext = (event?: React.MouseEvent) => {
    let newSkipped = skipped
    if (isStepSkipped(activeStepIndex)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStepIndex)
    }

    async function handleNextAsync(event?: React.MouseEvent) {
      try {
        setIsNextLoading(true)
        if (mode === 'static') {
          await steps[activeStepIndex]?.onNext?.(event)
        }
        setActiveStepIndex((prevActiveStep) => {
          logger.info({
            msg: 'stepsWithContext handleNext',
            prevActiveStep,
            mode,
            addStepValidation,
          })
          if (mode === 'dynamic') {
            return prevActiveStep + 1
          }
          return steps.length === prevActiveStep + 1 ? prevActiveStep : prevActiveStep + 1
        })
        setSkipped(newSkipped)
        setIsStepValid(false)
      } catch (error) {
        logger.info('stepsWithContext error', error)
      } finally {
        setIsNextLoading(false)
      }
    }
    handleNextAsync(event).catch(errorHandler)
  }

  const handleBack = (event?: React.MouseEvent) => {
    async function handleNextAsync(event?: React.MouseEvent) {
      try {
        setIsBackLoading(true)
        await steps[activeStepIndex]?.onBack?.(event)
        setActiveStepIndex((prevActiveStep) => prevActiveStep - 1)
        setIsStepValid(false)
      } finally {
        setIsBackLoading(false)
      }
    }
    handleNextAsync(event).catch(errorHandler)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStepIndex)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      console.warn("You can't skip a step that isn't optional.")
    }

    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1)
    setIsStepValid(false)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStepIndex)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStepIndex(initialActiveStepIndex ?? 0)
    setIsStepValid(false)
  }

  const restContext = {
    steps,
    isNextLoading,
    isBackLoading,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    activeStepIndex,
    activeId: steps[activeStepIndex]?.id ?? 0,
    initialActiveStepIndex,
    isStepValid,
    setIsStepValid,
    addStepValidation,
  }
  const stepsWithContext = steps.map((step, index) => ({
    ...step,
    children: (
      <WithFunctionalChildren<WithStepperFormProps & { stepIndex: number }>
        props={{
          ...restContext,
          stepIndex: index,
        }}
      >
        {step.children}
      </WithFunctionalChildren>
    ),
  }))

  logger.info({
    msg: 'stepsWithContext',
    all: {
      ...restContext,
      steps: stepsWithContext,
    },
    activeStep: stepsWithContext[activeStepIndex],
  })
  return {
    ...restContext,
    steps: stepsWithContext,
  }
}

const DEFAULT_STEPPER_FORM_PROPS = {
  activeId: '',
  activeStepIndex: 0,
  steps: [],
  isStepOptional: () => false,
  isStepSkipped: () => false,
  handleNext: () => {
    console.warn('handleNext is not implemented')
  },
  handleBack: () => {
    console.warn('handleBack is not implemented')
  },
  handleSkip: () => {
    console.warn('handleSkip is not implemented')
  },
  handleReset: () => {
    console.warn('handleReset is not implemented')
  },
  isNextLoading: false,
  isBackLoading: false,
  isStepValid: false,
  setIsStepValid: () => false,
}
// type guard
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTypeOfStepperFormChildren(children: ReactElement<any>): children is React.ReactElement<StepperFormChildren> {
  return children.type === Stepper || children.type === Action || children.type === Content
}

function Action({
  stepperFormProps = DEFAULT_STEPPER_FORM_PROPS,
  submitButtonProps,
  moreButtons,
}: StepperFormChildren & {
  submitButtonProps?: ButtonProps
  moreButtons?: CustomMenuItemProps[]
}) {
  const {
    activeStepIndex,
    addStepValidation,
    handleBack,
    handleNext,
    handleSkip,
    isNextLoading,
    isBackLoading,
    isStepOptional,
    isStepValid,
    steps,
  } = stepperFormProps
  const moreButtonItems = moreButtons?.filter((button) => !button.hide) ?? []

  return activeStepIndex === steps.length ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {!!moreButtonItems?.length && (
          <ActionMenuButton
            buttonData={{
              label: 'More',
              buttonProps: {
                variant: 'outlined',
              },
              menu: moreButtonItems,
            }}
          />
        )}
        {isStepOptional(activeStepIndex) && (
          <ActionButton
            buttonData={{
              label: 'Skip',
              onClick: handleSkip,
              loading: isNextLoading,
              color: 'inherit',
              buttonProps: { variant: 'outlined', style: { marginLeft: 0 } },
            }}
          />
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        <ActionButton
          buttonData={{
            label: 'Back',
            onClick: handleBack,
            loading: isBackLoading,
            color: 'inherit',
            buttonProps: {
              disabled: isBackLoading || isNextLoading || activeStepIndex === 0,
              variant: 'outlined',
            },
            disabledExplanation: activeStepIndex === 0 ? 'You are at the first step' : undefined,
          }}
        />

        <ButtonGroup variant="contained" sx={{ ml: 1 }}>
          <Button
            color="primary"
            onClick={submitButtonProps?.type === 'submit' ? undefined : handleNext}
            disabled={isBackLoading || isNextLoading || (addStepValidation ? !isStepValid : false)}
            {...submitButtonProps}
          >
            {isNextLoading && <CircularProgress size={16} sx={{ marginRight: 1 }} color="inherit" />}
            {activeStepIndex === steps.length - 1 ? 'Save' : 'Next'}
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  )
}

function NextOnlyAction({
  stepperFormProps = DEFAULT_STEPPER_FORM_PROPS,
  submitButtonProps,
  moreButtons,
}: StepperFormChildren & {
  submitButtonProps?: ButtonProps
  moreButtons?: CustomMenuItemProps[]
}) {
  const { activeStepIndex, steps, handleNext, isStepOptional, isNextLoading } = stepperFormProps
  const moreButtonItems = moreButtons?.filter((button) => !button.hide) ?? []

  return activeStepIndex === steps.length ? (
    <React.Fragment />
  ) : (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {isStepOptional(activeStepIndex) && (
          <ActionButton
            buttonData={{
              label: 'Skip',
              loading: isNextLoading,
              color: 'inherit',
              buttonProps: {
                onClick: submitButtonProps?.type === 'submit' ? undefined : handleNext,
                disabled: isNextLoading,
                variant: 'outlined',
                style: { marginLeft: 0 },
                name: 'skip',
                ...submitButtonProps,
              },
            }}
          />
        )}

        <Box sx={{ flex: '1 1 auto' }} />

        <ButtonGroup variant="contained" sx={{ ml: 1 }}>
          {!!moreButtonItems?.length && (
            <ActionMenu
              menuItems={moreButtonItems}
              buttonProps={{
                size: 'small',
              }}
            >
              <ArrowDropDown />
            </ActionMenu>
          )}
          <Button
            color="primary"
            onClick={submitButtonProps?.type === 'submit' ? undefined : handleNext}
            disabled={isNextLoading}
            name="submit"
            {...submitButtonProps}
          >
            {'Next'}
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  )
}

function Stepper({
  stepperFormProps = DEFAULT_STEPPER_FORM_PROPS,
  orientation = 'horizontal',
}: StepperFormChildren & { orientation?: Orientation }) {
  const { activeStepIndex, steps, isStepOptional, isStepSkipped } = stepperFormProps
  return (
    <Box sx={{ width: '100%' }}>
      <MuiStepper activeStep={activeStepIndex} sx={{ padding: 0 }} orientation={orientation}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={step.id} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          )
        })}
      </MuiStepper>
    </Box>
  )
}

function Content({ stepperFormProps = DEFAULT_STEPPER_FORM_PROPS }: StepperFormChildren) {
  const { activeStepIndex, steps } = stepperFormProps
  const activeStep = steps[activeStepIndex]
  return <Box sx={{ width: '100%' }}>{activeStep?.children}</Box>
}

function Form({ steps, children, mode, initialActiveStepIndex, addStepValidation }: StepperFormRootProps): JSX.Element {
  const stepperFormProps = useStepperFormProvider(steps, mode, initialActiveStepIndex, addStepValidation)
  return (
    <WithFunctionalChildren<{
      stepperFormProps: typeof stepperFormProps
    }>
      props={{ stepperFormProps }}
    >
      {children}
    </WithFunctionalChildren>
  )
}

export const BillyStepperForm = { Form, Stepper, Action, Content, NextOnlyAction }

// HOC function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function WithFunctionalChildren<T extends Record<string, any>>({
  children,
  props,
}: {
  children?: React.ReactNode | ((props: T) => React.ReactNode)
  props: T
}) {
  const isChildrenFunction = typeof children === 'function'
  return (
    <div>
      {isChildrenFunction && children({ ...props })}
      {!isChildrenFunction &&
        React.Children.map(React.Children.toArray(children), (child) => {
          if (React.isValidElement(child)) {
            if (isTypeOfStepperFormChildren(child)) {
              return React.cloneElement(child as React.ReactElement<StepperFormChildren>, { ...props })
            } else {
              return child
            }
          }
        })}
    </div>
  )
}
