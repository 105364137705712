import { useUserTenantSession } from '@/components/UserTenantSessionProvider/UserTenantSessionContext'
import { AmendSubscriptionPageState } from '@/pageComponents/orders/AmendmentOrderPage/AmendmentOrderPage'
import { CancellationOrderPageState } from '@/pageComponents/orders/CancellationOrderPage/CancellationOrderPage'
import { reconcileLineItemAction } from '@/util/charge'
import { getEnv } from '@/util/isEnv'
import buildLogger from '@/util/logger'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import isEqual from 'fast-deep-equal/es6/react'
import { current, Draft } from 'immer'
import { omit, pick } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import { deepMutable } from '../../../components/SchemaForm/DeepMutable'
import { useBillyRouter } from '../../../components/route/useBillyRouter'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { useJotaiUrqlQuery } from '../../../components/state/useJotaiUrqlQuery'
import {
  ActionType,
  BillingTerm,
  ChargeType,
  Cycle,
  DocumentTemplateType,
  GetAccountDocument,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetAttachmentsDocument,
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables,
  GetCurrentTenantDocument,
  GetCurrentTenantQuery,
  GetCurrentTenantQueryVariables,
  GetOpportunitiesByAccountCrmIdDocument,
  GetOpportunitiesByAccountCrmIdQuery,
  GetOpportunitiesByAccountCrmIdQueryVariables,
  GetOpportunitiesByHubSpotCompanyIdDocument,
  GetOpportunitiesByHubSpotCompanyIdQuery,
  GetOpportunitiesByHubSpotCompanyIdQueryVariables,
  GetOpportunitiesBySalesforceAccountIdDocument,
  GetOpportunitiesBySalesforceAccountIdQuery,
  GetOpportunitiesBySalesforceAccountIdQueryVariables,
  GetPaymentConfigurationDocument,
  GetPaymentConfigurationQuery,
  GetPaymentConfigurationQueryVariables,
  GetPaymentTermSettingsDocument,
  GetPaymentTermSettingsQuery,
  GetPaymentTermSettingsQueryVariables,
  GetSubscriptionDocument,
  GetSubscriptionQuery,
  GetSubscriptionQueryVariables,
  InputAmendmentOrderRequest,
  InputOrderLineItemRequest,
  LineItemFragment,
  ListAccountContactsDocument,
  ListAccountContactsQuery,
  ListAccountContactsQueryVariables,
  ListDiscountsDocument,
  ListDiscountsQuery,
  ListDiscountsQueryVariables,
  ListPlansDocument,
  ListPlansQuery,
  ListPlansQueryVariables,
  ListResellerAccountsDocument,
  ListResellerAccountsQuery,
  ListResellerAccountsQueryVariables,
  ListTemplatesByTypeDocument,
  ListTemplatesByTypeQuery,
  ListTemplatesByTypeQueryVariables,
  Opportunity,
  OrderDetailFragment,
  OrderType,
  Recurrence,
  UpsertOrderMutationVariables,
  useGetOrCreateAccountByCrmIdMutation,
  Version,
} from '../../../generated/graphql'
import { notEmpty, onlyUnique } from '../../../util/array'
import { unixToLuxonWithTz } from '../../../util/datetime/luxon/dateUtil'
import { trimDecimalPlaces } from '../../../util/string'
import { CancelAndRestructureFormData } from '../CancelAndRestructureOrderForm'
import { enforceSingleEntityContext } from '../orderPageDelegator'
import { NewOrderFormData } from './NewOrderPage'
import { useDryRunActions } from './context/DryRunActionsContext'
import { WritableDraft } from 'immer/dist/internal'
const logger = buildLogger('EditOrderPage/util')

function preselectOpportunityByCrmId(
  draft: NewOrderFormData,
  opportunities: ReadonlyArray<Opportunity>,
  opportunityCrmId?: string
) {
  if (opportunityCrmId) {
    const selectedOpportunity = opportunities?.find((o) => o.crmId === opportunityCrmId)
    if (selectedOpportunity) {
      draft.orderDetail.sfdcOpportunityId = selectedOpportunity?.crmId
      draft.orderDetail.sfdcOpportunityName = selectedOpportunity?.name
      draft.orderDetail.sfdcOpportunityStage = selectedOpportunity?.stage
      draft.orderDetail.sfdcOpportunityType = selectedOpportunity?.type
    }
  }
}

function preselectOpportunityByCrmIdForCancelAndRestructure(
  draft: CancelAndRestructureFormData,
  opportunities: ReadonlyArray<Opportunity>,
  opportunityCrmId?: string
) {
  if (opportunityCrmId) {
    const selectedOpportunity = opportunities?.find((o) => o.crmId === opportunityCrmId)
    if (selectedOpportunity) {
      draft.orderDetail.crmOpportunityId = selectedOpportunity?.crmId
      draft.orderDetail.crmOpportunityName = selectedOpportunity?.name
      draft.orderDetail.crmOpportunityStage = selectedOpportunity?.stage
      draft.orderDetail.crmOpportunityType = selectedOpportunity?.type
    }
  }
}

export function projectUpsertArgs(
  order: NewOrderFormData['orderDetail'],
  selectedPlans: NewOrderFormData['orderPlans'],
  invoiceTriggerSchedules?: NewOrderFormData['invoiceTriggerSchedules']
): UpsertOrderMutationVariables | undefined {
  const isRenewal = order.orderType === OrderType.Renewal
  const lineItems: InputOrderLineItemRequest[] = order.lineItems.map((lineItem) => {
    const isChargeRamped = order.lineItems.some((li) => li.chargeDetail.id === lineItem.chargeDetail.id && li.isRamp)
    return {
      action: lineItem.action ?? ActionType.Add,
      chargeId: lineItem.charge.id ?? '',
      subscriptionChargeId: lineItem.subscriptionChargeId ?? null,
      discounts: lineItem.discounts,
      effectiveDate: lineItem.effectiveDate,
      endDate: isChargeRamped ? lineItem.endDate : null,
      id: lineItem.id,
      isRamp: lineItem.isRamp,
      isDryRunItem: lineItem.isDryRunItem,
      predefinedDiscounts: lineItem.predefinedDiscounts?.map((orderDiscount) => orderDiscount?.id || ''),
      planId: lineItem.plan?.id ?? '',
      quantity: lineItem.quantity ?? lineItem.charge.defaultQuantity ?? lineItem.charge.minQuantity ?? 0,
      listUnitPrice: lineItem.charge.isCustom ? lineItem.listUnitPrice : null,
      listPriceOverrideRatio: lineItem.listPriceOverrideRatio
        ? +trimDecimalPlaces(lineItem.listPriceOverrideRatio.toString(), 10)
        : null,
      pricingOverride: lineItem.pricingOverride,
      attributeReferences: lineItem.attributeReferences?.map((attributeReference) => {
        return {
          attributeDefinitionId: attributeReference?.attributeDefinitionId || '',
          attributeValue: attributeReference?.attributeValue || '',
        }
      }),
      customFields: lineItem.customFields,
      arrOverride: lineItem?.arrOverride != lineItem?.metrics?.arrWithoutOverride ? lineItem?.arrOverride : null,
      replacedPlanId: lineItem.replacedPlan?.id,
    }
  })

  const orderFormTemplateIds = selectedPlans
    .map((plan) => plan.templateIds)
    .concat(order.orderFormTemplates.map((template) => template.id))
    .flat()
    .filter(onlyUnique)
    .filter(notEmpty)

  const orderPayload = {
    id: order.id,
    externalId: order.externalId,
    accountId: order.account?.id,
    approvalSegmentId: order.approvalSegment?.id,
    billingContactId: order.billingContact?.id,
    billingCycle: order.billingCycle,
    billingTerm: BillingTerm.UpFront,
    billingAnchorDate: order.billingAnchorDate,
    currency: order.currency,
    lineItems,
    customFields: order.customFields,
    name: order.name,
    orderFormTemplateIds,
    orderType: isRenewal ? OrderType.Renewal : OrderType.New,
    ownerId: order.owner?.id,
    paymentTerm: order.paymentTerm,
    predefinedDiscounts: order.predefinedDiscounts?.map((discount) => ({
      id: discount?.id,
      percent: discount?.percent,
    })),
    purchaseOrderNumber: order.purchaseOrderNumber,
    purchaseOrderRequiredForInvoicing: order.purchaseOrderRequiredForInvoicing,
    rampInterval: order.rampInterval,
    renewalForSubscriptionId: order.renewalForSubscription?.id,
    sfdcOpportunityId: order.sfdcOpportunityId,
    sfdcOpportunityName: order.sfdcOpportunityName,
    sfdcOpportunityStage: order.sfdcOpportunityStage,
    sfdcOpportunityType: order.sfdcOpportunityType,
    shippingContactId: order.shippingContact?.id,
    startDate: order.startDate,
    subscriptionId: order.subscriptionId,
    termLength: order.termLength?.step
      ? {
          cycle: order.termLength?.cycle || Cycle.Year,
          step: order.termLength?.step || 0,
        }
      : undefined,
    endDate: order.termLength?.step ? undefined : order.endDate,
    documentMasterTemplateId: order.documentMasterTemplate?.id,
    documentCustomContent: order.documentCustomContent,
    autoRenew: order.autoRenew,
    attachmentId: order.attachmentId,
    expiresOn: order.expiresOn,
    startDateType: order.startDateType,
  }

  const orderWithCustomBillingSchedule =
    order.billingCycle?.cycle === 'CUSTOM'
      ? {
          ...orderPayload,
          customBillingSchedule: {
            version: Version.V1,
            schedules: invoiceTriggerSchedules || [],
            orderId: order.id,
            orderLines: order.customBillingEligibleOrderLineIds || [],
          },
        }
      : orderPayload

  return {
    order: orderWithCustomBillingSchedule,
  }
}

export function projectCancellationOrderUpsertArgs(
  form: CancellationOrderPageState,
  isNew: boolean
): InputAmendmentOrderRequest {
  const orderFormTemplateIds = form.orderPlansForTerms
    .map((plan) => plan.templateIds)
    .concat(form.orderDetail.orderFormTemplates.map((template) => template.id))
    .flat()
    .filter(onlyUnique)
    .filter(notEmpty)
  return {
    approvalSegmentId: form.orderDetail.approvalSegment?.id ?? '',
    id: isNew ? undefined : form.orderDetail.id,
    name: form.orderDetail.name,
    billingContactId: form.orderDetail.billingContact?.id,
    orderFormTemplateIds,
    orderType: OrderType.Cancel,
    ownerId: form.orderDetail.owner?.id,
    shippingContactId: form.orderDetail.shippingContact?.id,
    startDate: form.orderDetail.startDate,
    subscriptionId: form.orderDetail.subscriptionId ?? '',
    documentMasterTemplateId: form.orderDetail.documentMasterTemplate?.id,
    sfdcOpportunityType: form.orderDetail.sfdcOpportunityType,
    sfdcOpportunityId: form.orderDetail.sfdcOpportunityId,
    sfdcOpportunityName: form.orderDetail.sfdcOpportunityName,
    sfdcOpportunityStage: form.orderDetail.sfdcOpportunityStage,
    expiresOn: form.orderDetail.expiresOn,
    documentCustomContent: form.orderDetail.documentCustomContent,
    customFields: form.orderDetail.customFields,
    creditableAmounts: form.orderDetail.creditableAmounts,
  }
}

export function mapAmendmentOrderUpsertArgs(
  form: AmendSubscriptionPageState,
  isNew: boolean
): InputAmendmentOrderRequest {
  const mutationLineItems: InputOrderLineItemRequest[] = form.orderDetail.lineItems.map((lineItem) => {
    // If the quantities haven't changed, set the action type to none to avoid a server side validation error
    const matchingCharge = form.orderDetail.currentSubscription?.charges.find(
      (charge) => charge?.id === lineItem.subscriptionChargeId
    )

    const action = reconcileLineItemAction({ lineItem, matchingCharge })

    return {
      action,
      chargeId: lineItem.charge.id ?? '',
      discounts: lineItem.discounts,
      id: lineItem.id,
      isRamp: lineItem.isRamp,
      planId: lineItem.plan?.id ?? '',
      effectiveDate: lineItem.action === ActionType.Add ? lineItem.effectiveDate : null,
      endDate:
        lineItem.action === ActionType.Add && lineItem.charge.type !== ChargeType.OneTime ? lineItem.endDate : null,
      predefinedDiscounts: lineItem.predefinedDiscounts?.map((orderDiscount) => orderDiscount?.id || ''),
      quantity: lineItem.quantity,
      listUnitPrice: lineItem.listUnitPrice,
      subscriptionChargeId: lineItem.subscriptionChargeId,
      listPriceOverrideRatio: lineItem.listPriceOverrideRatio
        ? +trimDecimalPlaces(lineItem.listPriceOverrideRatio.toString(), 10)
        : undefined,
      attributeReferences: lineItem.attributeReferences?.map((attributeReference) => {
        return {
          attributeDefinitionId: attributeReference?.attributeDefinitionId || '',
          attributeValue: attributeReference?.attributeValue || '',
        }
      }),
      customFields: lineItem.customFields,
      arrOverride: lineItem.arrOverride == lineItem.metrics?.arrWithoutOverride ? null : lineItem.arrOverride,
    }
  })

  const orderFormTemplateIds = form.orderPlansForTerms
    .map((plan) => plan.templateIds)
    .concat(form.orderDetail.orderFormTemplates.map((template) => template.id))
    .flat()
    .filter(onlyUnique)
    .filter(notEmpty)

  const args: InputAmendmentOrderRequest = {
    id: isNew ? undefined : form.orderDetail.id,
    name: form.orderDetail.name,
    billingContactId: form.orderDetail.billingContact?.id,
    approvalSegmentId: form.orderDetail.approvalSegment?.id,
    lineItems: mutationLineItems,
    orderFormTemplateIds,
    orderType: OrderType.Amendment,
    ownerId: form.orderDetail.owner?.id,
    predefinedDiscounts: form.orderDetail.predefinedDiscounts?.map((orderDiscount) => ({
      id: orderDiscount?.id || '',
      percent: orderDiscount?.percent,
    })),
    purchaseOrderNumber: form.orderDetail.purchaseOrderNumber,
    purchaseOrderRequiredForInvoicing: form.orderDetail.purchaseOrderRequiredForInvoicing,
    sfdcOpportunityType: form.orderDetail.sfdcOpportunityType,
    sfdcOpportunityId: form.orderDetail.sfdcOpportunityId,
    sfdcOpportunityName: form.orderDetail.sfdcOpportunityName,
    sfdcOpportunityStage: form.orderDetail.sfdcOpportunityStage,
    shippingContactId: form.orderDetail.shippingContact?.id,
    startDate: form.orderDetail.startDate,
    subscriptionId: form.orderDetail.subscriptionId ?? '',
    documentMasterTemplateId: form.orderDetail.documentMasterTemplate?.id,
    documentCustomContent: form.orderDetail.documentCustomContent,
    autoRenew: form.orderDetail.autoRenew,
    expiresOn: form.orderDetail.expiresOn,
    rampInterval: form.orderDetail.rampInterval,
    customFields: form.orderDetail.customFields,
    attachmentId: form.orderDetail.attachmentId,
    creditableAmounts: form.orderDetail.creditableAmounts,
  }

  const orderWithCustomBillingSchedule =
    form.orderDetail.billingCycle?.cycle === 'CUSTOM'
      ? {
          ...args,
          customBillingSchedule: {
            version: Version.V1,
            schedules:
              form.invoiceTriggerSchedules?.filter((schedule) => schedule.amount != null && schedule.amount >= 0) || [],
            orderId: form.orderDetail.id,
            orderLines: form.orderDetail.customBillingEligibleOrderLineIds || [],
          },
        }
      : args

  return orderWithCustomBillingSchedule
}

export function calculateOrderEndDate(startDate: number, termLength: Recurrence) {
  const startDateObj = unixToLuxonWithTz(startDate)
  const termCycleUnit = termLength.cycle === 'MONTH' ? 'month' : 'year'
  return startDateObj.plus({ [termCycleUnit]: termLength.step })
}

export const disableRamp =
  (lineItem: OrderDetailFragment['lineItems'][0]) =>
  (draft: Draft<NewOrderFormData>): void => {
    const lineItems = draft.orderDetail.lineItems
    const firstLineItemIndex = lineItems?.findIndex(
      (li) => li.plan?.id === lineItem.plan?.id && li.charge?.id === lineItem.charge.id
    )
    const filteredLineItems = lineItems?.filter(
      (li) => !(li.charge?.id === lineItem.charge.id && li.plan?.id === lineItem.plan?.id)
    )
    if (firstLineItemIndex !== undefined && firstLineItemIndex >= 0 && filteredLineItems) {
      filteredLineItems.splice(
        firstLineItemIndex,
        0,
        deepMutable({
          action: lineItem.action,
          charge: lineItem.charge,
          chargeDetail: lineItem.chargeDetail,
          listUnitPrice: lineItem.charge.isCustom ? lineItem.listUnitPrice ?? 0 : null,
          plan: lineItem.plan,
          predefinedDiscounts: lineItem.predefinedDiscounts,
          quantity: lineItem.quantity,
          discounts: lineItem.discounts,
          listPriceOverrideRatio: lineItem.listPriceOverrideRatio,
          isRamp: false,
          subscriptionChargeId: lineItem.subscriptionChargeId ?? null,
          arrOverride: null,
        })
      )
    }
    draft.orderDetail.lineItems = filteredLineItems
  }

export const enableRamp =
  (lineItem: OrderDetailFragment['lineItems'][0], intervals?: number[]) =>
  (draft: Draft<NewOrderFormData>): void => {
    const lineItems = draft.orderDetail.lineItems
    let currentLineItemIndex = lineItems.findIndex(
      // ramp can only be enabled if there is one instance of the charge present
      (li) => li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id
    )
    if (draft.orderDetail.orderType === OrderType.Restructure) {
      currentLineItemIndex = lineItems.findIndex(
        (li) =>
          li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id && li.action !== ActionType.Remove
      )
    }
    if (currentLineItemIndex !== undefined && currentLineItemIndex >= 0) {
      draft.orderDetail.rampInterval?.forEach((interval, i) => {
        const newLineItem: OrderDetailFragment['lineItems'][0] = {
          action:
            lineItems[currentLineItemIndex].action === ActionType.Restructure ? ActionType.Restructure : ActionType.Add,
          charge: lineItem.charge,
          chargeDetail: lineItem.chargeDetail,
          listUnitPrice: lineItem.charge.isCustom ? lineItem.listUnitPrice ?? 0 : null,
          effectiveDate: intervals?.[i] || interval,
          plan: lineItem.plan,
          predefinedDiscounts: lineItem.predefinedDiscounts,
          quantity: lineItem.quantity,
          discounts: lineItem.discounts,
          subscriptionChargeId: lineItem.subscriptionChargeId,
          listPriceOverrideRatio: lineItem.listPriceOverrideRatio,
          attributeReferences: lineItem.attributeReferences,
          isRamp: true,
          arrOverride: null,
        }
        lineItems?.splice(currentLineItemIndex + i, i === 0 ? 1 : 0, deepMutable(newLineItem))
      })
    }
  }
export function reapplyRampOnLineItems(draft: Draft<NewOrderFormData>) {
  logger.trace('Starting reapplyRampOnLineItems')

  // Step 1: Keep a copy of old line items
  const oldLineItems = current(draft).orderDetail.lineItems

  // Step 2: Group ramp items by their plan and charge and maintain order
  const rampGroups = new Map<string, LineItemFragment[]>()
  const allGroups = new Map<string, LineItemFragment[]>()
  oldLineItems.forEach((item) => {
    const key = `${item.plan?.id}-${item.charge.id}`
    if (!allGroups.has(key)) {
      allGroups.set(key, [])
    }
    allGroups.get(key)?.push(item)

    if (item.isRamp) {
      if (!rampGroups.has(key)) {
        rampGroups.set(key, [])
      }
      rampGroups.get(key)?.push(item)
    }
  })

  // Step 3: Reapply ramps for each group
  rampGroups.forEach((items) => {
    if (items.length > 0) {
      const lineItem = items[0]
      disableRamp(lineItem)(draft)
      enableRamp(lineItem)(draft)
    }
  })

  // Step 4: Create new line items array by processing each group
  const newLineItemGroups: LineItemFragment[][] = []
  allGroups.forEach((oldGroup, groupKey) => {
    const orderDetail = current(draft).orderDetail
    const newGroup = orderDetail.lineItems
      .filter((item) => `${item.plan?.id}-${item.charge.id}` === groupKey)
      .sort((a, b) => (a.effectiveDate ?? 0) - (b.effectiveDate ?? 0))

    // Rebuild each line item in the group
    const updatedGroup = newGroup.map((newLineItem, index) => {
      if (newLineItem.isRamp) {
        // For existing indices, preserve old values but update dates
        const dateFields: (keyof LineItemFragment)[] = ['effectiveDate', 'endDate']
        const uniqueNewLineFields: (keyof LineItemFragment)[] = ['id']
        if (index < oldGroup.length) {
          return {
            // omit is needed to prevent the case newLineItem is not populated and oldGroup has carry over stale dates
            ...omit(oldGroup[index], dateFields),
            ...pick(newLineItem, dateFields),
          }
        } else {
          return {
            // when the ramp is completely new, we carry over the latest interval's attributes
            ...omit(oldGroup.slice(-1)[0], dateFields, uniqueNewLineFields),
            ...pick(newLineItem, dateFields, uniqueNewLineFields),
          }
        }
      }

      if (!newLineItem.isRamp) {
        return {
          ...newLineItem,
          effectiveDate: draft.orderDetail.startDate,
          endDate: draft.orderDetail.endDate,
          arrOverride: null,
        }
      }

      return newLineItem
    })
    newLineItemGroups.push(updatedGroup)
  })

  draft.orderDetail.lineItems = ([] as LineItemFragment[]).concat(...newLineItemGroups) as WritableDraft<
    LineItemFragment[]
  >

  logger.trace({
    msg: 'Completed reapplyRampOnLineItems',
    finalLineItems: current(draft).orderDetail.lineItems,
  })
}

// find the ramp interval of a given effective date and return the end date of the ramp interval
function getRampPeriodEndDate(
  rampIntervals: readonly (number | null)[] | null | undefined,
  effectiveDate: number
): number | null {
  if (!rampIntervals) {
    return null
  }
  const index = rampIntervals.indexOf(effectiveDate)
  if (index === -1 || index + 1 >= rampIntervals.length) {
    return null
  }
  return rampIntervals[index + 1]
}

export const duplicateLineItem =
  (lineItemIndex: number) =>
  (draft: Draft<NewOrderFormData>): void => {
    const lineItems = draft.orderDetail.lineItems
    const lineItem = lineItems?.[lineItemIndex]
    const currentLineItemIndex = lineItemIndex
    if (currentLineItemIndex !== undefined && currentLineItemIndex >= 0 && lineItem.effectiveDate) {
      const newEffectiveDate =
        lineItem.effectiveDate < draft.orderDetail.startDate ? draft.orderDetail.startDate : lineItem.effectiveDate
      const newEndDate =
        lineItem.charge.type === ChargeType.OneTime && lineItem.charge.durationInMonths !== null
          ? null // One time charge needs to let backend calculate the end date
          : lineItem.endDate ??
            (lineItem.isRamp ? getRampPeriodEndDate(draft.orderDetail.rampInterval, lineItem.effectiveDate) : null)
      const newLineItem: OrderDetailFragment['lineItems'][0] = {
        ...lineItem,
        id: null,
        subscriptionChargeId: null,
        subscriptionChargeGroupId: null,
        action: ActionType.Add,
        isRamp: false,
        effectiveDate: newEffectiveDate,
        endDate: newEndDate,
      }
      lineItems.splice(currentLineItemIndex + 1, 0, deepMutable(newLineItem))
    }
  }

export const deleteLineItem =
  (lineItemIndex: number) =>
  (draft: Draft<NewOrderFormData>): void => {
    const lineItems = draft.orderDetail.lineItems
    const lineItem = draft.orderDetail.lineItems?.[lineItemIndex]
    if (lineItemIndex !== undefined && lineItemIndex >= 0) {
      if (lineItem.action === ActionType.Add || lineItem.action === ActionType.Renewal) {
        lineItems?.splice(lineItemIndex, 1)
      } else {
        lineItems[lineItemIndex].action = ActionType.Remove
        lineItems[lineItemIndex].quantity = 0
      }
    }
  }

export const hasChangedBuilder =
  <T, R>(oldValue: T, newValue: T) =>
  (selector: (value: T) => R): boolean => {
    return !isEqual(selector(oldValue), selector(newValue))
  }

export const useNewOrderPageJotaiQueries = () => {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const router = useBillyRouter()
  const accountId = router.query.accountId as string | undefined
  const accountCrmId = router.query.accountCrmId as string | undefined
  const opportunityCrmId = router.query.opportunityCrmId as string | undefined

  useJotaiUrqlQuery<NewOrderFormData, GetAccountQuery, GetAccountQueryVariables>({
    document: GetAccountDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.orderDetail.account = {
        id: data.accountDetail.id,
        name: data.accountDetail.name,
        currency: data.accountDetail.currency,
        crmId: data.accountDetail.crmId,
        hasAutomaticPayment: data.accountDetail.hasAutomaticPayment,
      }
      draft.orderDetail.currency = data.accountDetail.currency
    }, []),
    pause: useCallback(() => !accountId, [accountId]),
    variables: useCallback(() => ({ id: accountId as string }), [accountId]),
  })

  const errorHandler = useErrorHandler()

  const [, getOrCreateAccountByCrmId] = useGetOrCreateAccountByCrmIdMutation()

  useEffect(() => {
    if (!accountCrmId) {
      return
    }
    async function doAsync() {
      if (accountCrmId) {
        const result = await getOrCreateAccountByCrmId({ accountCrmId, opportunityCrmId })
        const account = result.data?.getOrCreateAccountByCrmId
        jotaiForm.set((draft) => {
          if (account) {
            draft.orderDetail.account = {
              id: account.id,
              name: account.name,
              currency: account.currency,
              crmId: account.crmId,
              hasAutomaticPayment: account.hasAutomaticPayment,
            }
            draft.orderDetail.currency = account.currency
          }
        })
      }
    }
    doAsync().catch(errorHandler)
  }, [accountCrmId, opportunityCrmId, errorHandler, getOrCreateAccountByCrmId, jotaiForm])

  useJotaiUrqlQuery<NewOrderFormData, GetPaymentConfigurationQuery, GetPaymentConfigurationQueryVariables>({
    document: GetPaymentConfigurationDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.paymentConfiguration = deepMutable(data.paymentConfiguration)), []),
  })

  useJotaiUrqlQuery<NewOrderFormData, GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>(
    useMemo(
      () => ({
        document: GetPaymentTermSettingsDocument,
        jotaiForm,
        onData: (data, draft) => {
          draft.paymentTermSettings = deepMutable(data.paymentTermSettings)
        },
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<NewOrderFormData, ListPlansQuery, ListPlansQueryVariables>({
    document: ListPlansDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.plansList = deepMutable(data.plans)), []),
  })

  useJotaiUrqlQuery<NewOrderFormData, ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>({
    document: ListTemplatesByTypeDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.templateList = deepMutable(data.documentTemplates)), []),
    variables: useCallback(() => ({ type: DocumentTemplateType.Order }), []),
  })

  useJotaiUrqlQuery<NewOrderFormData, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.contactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  useJotaiUrqlQuery<NewOrderFormData, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.billingContactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !(form.orderDetail.resoldBy?.id || form.orderDetail.account?.id), []),
    variables: useCallback(
      (form) => ({ accountId: form.orderDetail.resoldBy?.id || form.orderDetail.account?.id || '' }),
      []
    ),
  })

  useJotaiUrqlQuery<
    NewOrderFormData,
    GetOpportunitiesBySalesforceAccountIdQuery,
    GetOpportunitiesBySalesforceAccountIdQueryVariables
  >({
    document: GetOpportunitiesBySalesforceAccountIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesBySalesforceAccountId)
        if (data?.opportunitiesBySalesforceAccountId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesBySalesforceAccountId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) =>
        !form.orderDetail.account?.crmId || !form.hasSalesforceIntegration || !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ sfdcAccountId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    NewOrderFormData,
    GetOpportunitiesByHubSpotCompanyIdQuery,
    GetOpportunitiesByHubSpotCompanyIdQueryVariables
  >({
    document: GetOpportunitiesByHubSpotCompanyIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByHubSpotCompanyId)
        if (data?.opportunitiesByHubSpotCompanyId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByHubSpotCompanyId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) => !form.orderDetail.account?.crmId || !form.hasHubSpotIntegration || !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ companyId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    NewOrderFormData,
    GetOpportunitiesByAccountCrmIdQuery,
    GetOpportunitiesByAccountCrmIdQueryVariables
  >({
    document: GetOpportunitiesByAccountCrmIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByAccountCrmId)
        if (data?.opportunitiesByAccountCrmId) {
          preselectOpportunityByCrmId(draft, data.opportunitiesByAccountCrmId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) =>
        !form.orderDetail.account?.crmId ||
        form.hasHubSpotIntegration ||
        form.hasSalesforceIntegration ||
        !!form.orderDetail.compositeOrderId,
      []
    ),
    variables: useCallback((form) => ({ accountCrmId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<NewOrderFormData, ListDiscountsQuery, ListDiscountsQueryVariables>(
    useMemo(
      () => ({
        document: ListDiscountsDocument,
        jotaiForm,
        onData: (data, draft) => (draft.discountsList = deepMutable(data.discounts)),
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<NewOrderFormData, GetCurrentTenantQuery, GetCurrentTenantQueryVariables>({
    document: GetCurrentTenantDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.hasSalesforceIntegration = data.currentTenant.hasSalesforceIntegration
      draft.hasHubSpotIntegration = data.currentTenant.hasHubSpotIntegration
    }, []),
  })

  useJotaiUrqlQuery<NewOrderFormData, GetAttachmentsQuery, GetAttachmentsQueryVariables>({
    document: GetAttachmentsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.attachments = deepMutable(data.attachments)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })
}

export const useCancelAndRestructureOrderPageJotaiQueries = () => {
  const jotaiForm = useJotaiFormContext<CancelAndRestructureFormData>()
  const router = useBillyRouter()
  const userTenantSession = useUserTenantSession()
  const currentUser = userTenantSession.currentUser

  const subscriptionId = router.query.subscriptionId as string | undefined
  const opportunityCrmId = router.query.opportunityCrmId as string | undefined

  useJotaiUrqlQuery<CancelAndRestructureFormData, GetCurrentTenantQuery, GetCurrentTenantQueryVariables>({
    document: GetCurrentTenantDocument,
    jotaiForm,
    onData: useCallback((data, draft) => {
      draft.hasSalesforceIntegration = data.currentTenant.hasSalesforceIntegration
      draft.hasHubSpotIntegration = data.currentTenant.hasHubSpotIntegration
    }, []),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, GetSubscriptionQuery, GetSubscriptionQueryVariables>({
    document: GetSubscriptionDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.subscription = deepMutable(data.subscriptions[0])), []),
    pause: useCallback(() => !subscriptionId, [subscriptionId]),
    variables: useCallback(() => ({ id: subscriptionId || '' }), [subscriptionId]),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListTemplatesByTypeQuery, ListTemplatesByTypeQueryVariables>({
    document: ListTemplatesByTypeDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.templateList = deepMutable(data.documentTemplates)), []),
    variables: useCallback(() => ({ type: DocumentTemplateType.Order }), []),
  })

  const subscriptionEntityId = jotaiForm.useSelect(useCallback((form) => form.subscription?.entityId, []))

  // enforce single entity context
  enforceSingleEntityContext(subscriptionEntityId, currentUser)

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.contactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListAccountContactsQuery, ListAccountContactsQueryVariables>({
    document: ListAccountContactsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.billingContactList = deepMutable(data.accountContacts)), []),
    pause: useCallback((form) => !(form.orderDetail.resoldBy?.id || form.orderDetail.account?.id), []),
    variables: useCallback(
      (form) => ({ accountId: form.orderDetail.resoldBy?.id || form.orderDetail.account?.id || '' }),
      []
    ),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListResellerAccountsQuery, ListResellerAccountsQueryVariables>({
    document: ListResellerAccountsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.resellerAccountList = deepMutable(data.accounts)), []),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, GetPaymentTermSettingsQuery, GetPaymentTermSettingsQueryVariables>(
    useMemo(
      () => ({
        document: GetPaymentTermSettingsDocument,
        jotaiForm,
        onData: (data, draft) => {
          draft.paymentTermSettings = deepMutable(data.paymentTermSettings)
        },
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListPlansQuery, ListPlansQueryVariables>({
    document: ListPlansDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.plansList = deepMutable(data.plans)), []),
  })

  useJotaiUrqlQuery<
    CancelAndRestructureFormData,
    GetOpportunitiesBySalesforceAccountIdQuery,
    GetOpportunitiesBySalesforceAccountIdQueryVariables
  >({
    document: GetOpportunitiesBySalesforceAccountIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesBySalesforceAccountId)
        if (data?.opportunitiesBySalesforceAccountId) {
          preselectOpportunityByCrmIdForCancelAndRestructure(
            draft,
            data.opportunitiesBySalesforceAccountId,
            opportunityCrmId
          )
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback((form) => !form.orderDetail.account?.crmId || !form.hasSalesforceIntegration, []),
    variables: useCallback((form) => ({ sfdcAccountId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<
    CancelAndRestructureFormData,
    GetOpportunitiesByHubSpotCompanyIdQuery,
    GetOpportunitiesByHubSpotCompanyIdQueryVariables
  >({
    document: GetOpportunitiesByHubSpotCompanyIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByHubSpotCompanyId)
        if (data?.opportunitiesByHubSpotCompanyId) {
          preselectOpportunityByCrmIdForCancelAndRestructure(
            draft,
            data.opportunitiesByHubSpotCompanyId,
            opportunityCrmId
          )
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback((form) => !form.orderDetail.account?.crmId || !form.hasHubSpotIntegration, []),
    variables: useCallback((form) => ({ companyId: form.orderDetail.account?.crmId || '' }), []),
  })

  useJotaiUrqlQuery<CancelAndRestructureFormData, ListDiscountsQuery, ListDiscountsQueryVariables>(
    useMemo(
      () => ({
        document: ListDiscountsDocument,
        jotaiForm,
        onData: (data, draft) => (draft.discountsList = deepMutable(data.discounts)),
      }),
      [jotaiForm]
    )
  )

  useJotaiUrqlQuery<CancelAndRestructureFormData, GetAttachmentsQuery, GetAttachmentsQueryVariables>({
    document: GetAttachmentsDocument,
    jotaiForm,
    onData: useCallback((data, draft) => (draft.attachments = deepMutable(data.attachments)), []),
    pause: useCallback((form) => !form.orderDetail.account?.id, []),
    variables: useCallback((form) => ({ accountId: form.orderDetail.account?.id || '' }), []),
  })

  useJotaiUrqlQuery<
    CancelAndRestructureFormData,
    GetOpportunitiesByAccountCrmIdQuery,
    GetOpportunitiesByAccountCrmIdQueryVariables
  >({
    document: GetOpportunitiesByAccountCrmIdDocument,
    jotaiForm,
    onData: useCallback(
      (data, draft) => {
        draft.opportunities = deepMutable(data.opportunitiesByAccountCrmId)
        if (data?.opportunitiesByAccountCrmId) {
          preselectOpportunityByCrmIdForCancelAndRestructure(draft, data.opportunitiesByAccountCrmId, opportunityCrmId)
        }
      },
      [opportunityCrmId]
    ),
    pause: useCallback(
      (form) => !form.orderDetail.account?.crmId || form.hasHubSpotIntegration || form.hasSalesforceIntegration,
      []
    ),
    variables: useCallback((form) => ({ accountCrmId: form.orderDetail.account?.crmId || '' }), []),
  })
}

export const getDGPSortedColumns = (columns: GridColDef[]) => {
  const envInfo = getEnv()
  const savedTableStateString = window.localStorage.getItem('data-grid-pro-state')
  const savedTableStateEnv = window.localStorage.getItem('data-grid-pro-state-env')
  const savedTableState: GridInitialStatePro | undefined =
    savedTableStateString && savedTableStateEnv === envInfo ? JSON.parse(savedTableStateString) : undefined

  const sortedColumns = savedTableState?.columns?.orderedFields
    ?.map((field) => {
      const column = columns.find((col) => col.field === field)
      return column
    })
    .filter((column) => column !== undefined) as GridColDef[] | undefined

  return sortedColumns
}

export const useApplyRampToLineItems = () => {
  const jotaiForm = useJotaiFormContext<NewOrderFormData>()
  const { queueDryRun } = useDryRunActions()
  let rampDisabledExplanation = ''

  const { lineItems, isRampEnabled } = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        lineItems: form.orderDetail.lineItems,
        isRampEnabled: form.orderDetail.rampInterval && form.orderDetail.rampInterval.length > 0,
      }),
      []
    )
  )
  const handleApplyRampToLineItems = useCallback(() => {
    jotaiForm.set((draft) => {
      enableRampOnAllLineItems(draft)
    })
    queueDryRun()
  }, [jotaiForm, queueDryRun])

  const rampApplicableLineItems = lineItems.filter(
    (lineItem) =>
      lineItem.action !== ActionType.Remove && !lineItem.isRamp && lineItem.charge.type !== ChargeType.OneTime
  )
  if (rampApplicableLineItems.length === 0) {
    rampDisabledExplanation = 'No charges are eligible to apply ramps.'
  }

  if (!isRampEnabled) {
    rampDisabledExplanation = 'Please select a ramp interval type and duration enable ramps on charges.'
  }

  return { handleApplyRampToLineItems, rampDisabledExplanation }
}

const enableRampOnAllLineItems = (draft: Draft<NewOrderFormData>) => {
  const lineItems = draft.orderDetail.lineItems
  const rampApplicableLineItems = lineItems.filter(
    (lineItem) =>
      lineItem.action !== ActionType.Remove && !lineItem.isRamp && lineItem.charge.type !== ChargeType.OneTime
  )
  rampApplicableLineItems.forEach((lineItem) => {
    let currentLineItemIndex = lineItems.findIndex(
      // ramp can only be enabled if there is one instance of the charge present
      (li) => li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id
    )
    if (draft.orderDetail.orderType === OrderType.Restructure) {
      currentLineItemIndex = lineItems.findIndex(
        (li) =>
          li.plan?.id === lineItem.plan?.id && li.charge.id === lineItem.charge.id && li.action !== ActionType.Remove
      )
    }
    if (currentLineItemIndex !== undefined && currentLineItemIndex >= 0) {
      draft.orderDetail.rampInterval?.forEach((interval, i) => {
        const newLineItem: OrderDetailFragment['lineItems'][0] = {
          action:
            lineItems[currentLineItemIndex].action === ActionType.Restructure ? ActionType.Restructure : ActionType.Add,
          charge: lineItem.charge,
          chargeDetail: lineItem.chargeDetail,
          listUnitPrice: lineItem.charge.isCustom ? lineItem.listUnitPrice ?? 0 : null,
          effectiveDate: interval,
          plan: lineItem.plan,
          predefinedDiscounts: lineItem.predefinedDiscounts,
          quantity: lineItem.quantity,
          subscriptionChargeId: lineItem.subscriptionChargeId,
          listPriceOverrideRatio: lineItem.listPriceOverrideRatio,
          attributeReferences: lineItem.attributeReferences,
          isRamp: true,
          arrOverride: null,
        }
        lineItems?.splice(currentLineItemIndex + i, i === 0 ? 1 : 0, deepMutable(newLineItem))
      })
    }
  })
}
